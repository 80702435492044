import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p>scenarii-prazdnikov.com</p>
      <span>Made with <b>React.js</b></span>
    </footer>
  )
}

export default Footer
